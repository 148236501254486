import i18next from "i18next";
import Backend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend"; // primary use cache
import XHR from "i18next-xhr-backend"; // fallback xhr load
import { initReactI18next } from "react-i18next";
import env from "@beam-australia/react-env";
import mergeWith from "lodash/mergeWith";
import isNil from "lodash/isNil";

export const locales = [
    "da-DK",
    "en-US",
    "es-ES",
    "fr-FR",
    "ja-JP",
    "de-DE",
    "pt-PT",
    "it-IT",
    "nl-NL",
] as const;

export class LocalizationService {
    private readonly defaultNamespace = "translations";
    private readonly getLocaleShortDateFormat = (locale: string) => {
        const formatter = new Intl.DateTimeFormat(locale).formatToParts();

        return formatter
            .map(e => {
                switch (e.type) {
                    case "month":
                        return "MM";
                    case "day":
                        return "DD";
                    case "year":
                        return "YYYY";
                    default:
                        return e.value;
                }
            })
            .join("");
    };
    public static readonly defaultLocale: (typeof locales)[number] = "en-US";
    private static instance: LocalizationService;

    private constructor() {}

    public static getInstance(): LocalizationService {
        return this.instance;
    }

    private static setInstance(service: LocalizationService) {
        LocalizationService.instance = service;
    }

    private async setupI18next(options: any) {
        const i18expirationTime = Number(env("I18_EXPIRATIONTIME_SECONDS")) * 1000;
        const defaultOptions = {
            backend: {
                backends: [
                    LocalStorageBackend, // primary
                    XHR, // fallback
                ],
                backendOptions: [
                    {
                        prefix: "nexus_translation_res_",
                        expirationTime: i18expirationTime,
                    },
                    {
                        loadPath: `${env("ROUTER_BASE_PATH")}/locales/{{lng}}/{{ns}}.json`, // xhr load path for my own fallback
                    },
                ],
            },
            partialBundledLanguages: true,
            ns: [this.defaultNamespace],
            defaultNS: this.defaultNamespace,
            interpolation: {
                // React already does escaping
                escapeValue: false,
            },
            react: {
                useSuspense: true,
            },
            debug: false,
            load: "currentOnly", // load only from "/en-US/" not "/en/" as well
            lng: this.getCurrentLocale(),
            fallbackLng: LocalizationService.defaultLocale,
        };
        const effectiveOptions = mergeWith(defaultOptions, options, (_objValue, srcValue) =>
            Array.isArray(srcValue) ? srcValue : undefined
        );
        await i18next.use(Backend).use(initReactI18next).init(effectiveOptions);
    }

    public static async createInstance(options?: any) {
        const service = new LocalizationService();
        await service.setupI18next(options);

        LocalizationService.setInstance(service);
    }

    public getCurrentLocale(): string {
        let locale = i18next.language;

        if (!locale) {
            locale = this.getValidOrFallbackLocale(navigator.language)!;
        }
        return locale;
    }

    public async setUiLocale(locale: string) {
        if (!this.isValidLocale(locale)) {
            locale = LocalizationService.defaultLocale;
        }

        if (i18next.language === locale) {
            return;
        }

        return i18next.changeLanguage(locale).catch(error => {
            console.error("ERROR: ", error);
            throw error;
        });
    }

    public translate(key: string, options?: string | any): string {
        if (options) return i18next.t(key, options) as string;

        return i18next.t(key);
    }

    public isValidLocale(locale: string | undefined): boolean {
        if (isNil(locale)) {
            return false;
        }

        const language = this.getLanguageIdentifier(locale);
        return locales.some(code => code.startsWith(language));
    }

    public getValidOrFallbackLocale(locale: string | undefined): string | undefined {
        if (isNil(locale)) {
            return LocalizationService.defaultLocale;
        }
        const supportedLocale = locales.find(l => l === locale);
        if (!isNil(supportedLocale)) return supportedLocale;
        const language = this.getLanguageIdentifier(locale);
        const fallbackLocale = locales.find(l => l.startsWith(language));
        if (!isNil(fallbackLocale)) return fallbackLocale;
        return LocalizationService.defaultLocale;
    }

    private getLanguageIdentifier(locale: string) {
        return locale.split("-")[0];
    }

    public getLocaleObjectByCode(otherCode: string) {
        const ret = locales.filter(code => code === otherCode);
        return ret[0];
    }

    public dateToCurrentLocaleDateString(date: Date): string {
        return date.toLocaleDateString(this.getCurrentLocale(), {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    }

    public dateToCurrentLocaleDateTimeString(date: Date): string {
        return date.toLocaleString(this.getCurrentLocale());
    }

    public getCurrentLocaleDateFormat(): string {
        return this.getLocaleShortDateFormat(this.getLocaleObjectByCode(this.getCurrentLocale()));
    }

    public getDefaultDateFormat(date: Date) {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }
}

export function t(key: any, options?: string | any) {
    return LocalizationService.getInstance().translate(key, options);
}

export function localization(): LocalizationService {
    return LocalizationService.getInstance();
}

export const initializeLocale = () => localization().getCurrentLocale();
export type SupportedLanguage = (typeof locales)[number];
export const useCurrentLocale = () => {
    const locale = locales.find(x => x === i18next.language);
    if (locale) return locale;
    throw new Error(`Unsupported locale: ${i18next.language}`);
};
